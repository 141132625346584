import imagesLoaded from 'imagesloaded';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

import gsap from '../lib/gsap.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollToPlugin from '../lib/ScrollToPlugin.min';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

import {logoAnimation} from '../js/logoAnimation.js';
import {destroyThree} from '../js/logoAnimation.js';

import Swiper from 'swiper/bundle';
import 'swiper/css';

export function main() {
	
var Module = {};
var body;

Module.Compornent = {
	mobileToggleAsset: false,
	checkClass: function($this){
		$this = null;
	}
};

(function($) {
Module.Events = (function (){
'use strict';

var OBSERVER = {
	handlers: [],
	observeEvents: function(target){
		this.handlers.push(target);
	},
	deleteListener: function(element,listener){
		element.off(listener);
		element = null;
		listener = null;
	},
	clearEvents: function(){
		$(window).off('scroll resize');
		// cancelAnimationFrame(timeReq);
	}
};

var winH = $(window).height();
var winW = $(window).width();
var body = $('body');
var footerH = document.querySelector('footer').offsetHeight;


function nav_show(){
	body.addClass('navopen');
	navlistAnime();
}
function nav_hide(){
	body.removeClass('navopen');
}
function navInit(){
	nav_current();
	$('#nav_btn').on('click', function(){
		nav_show();
	});
	$('#navclose').on('click', function(){
		nav_hide();
	});
}

function navlistAnime (){
	gsap.from('#main_nav .navli', {
		y:60,
		opacity:0,
		duration: 0.8,
		ease: "power4.out",
		stagger: 0.05,
	});
}


var nav = $("#nav");
var $navLi = nav.find("li");
function nav_current(){
	$navLi.removeClass("on");
}


function elFunc(){
	document.querySelectorAll('.el').forEach((_tar, i) => {
		let _elmc = _tar.querySelectorAll('.elc');
		if( _elmc.length == 0 ){
			_elmc = _tar;
		}
		gsap.fromTo(_elmc,
			{ opacity:0, y:60, },
			{ opacity:1, y:0, 
				duration: 1.2,
				ease: "power4.out",
 				stagger: 0.1,
				scrollTrigger: {
					trigger: _tar,
					start: "top bottom-=10%",
					onRefresh: self => self.progress === 1 && self.animation.progress(1),
				}
			}
		);
	});

	const up_scrollTrigger = ScrollTrigger.create({
		trigger: body,
		start: "top top",
		end: "bottom bottom+="+footerH+"px",
		onUpdate: self => {
			const _slfscrl = self.scroll();
			if(self.direction == -1){
				document.body.classList.remove('scrldown');
			}else{
				if(_slfscrl > 10){
					if(self.prevSclrl < _slfscrl-1){
						document.body.classList.add('scrldown');
					}
					self.prevSclrl = _slfscrl;
				}
			}
			scrltop (_slfscrl);
			scrlend (self);
		},
	});
}
const scrltop = (_slfscrl) => {
	if(_slfscrl > 10){
		body.removeClass('scrltop');
		body.addClass('scrl');
	}else{
		body.removeClass('scrl');
		body.addClass('scrltop');
	}
}

const scrlend = (self) => {
	if(self.progress >= 1){
		body.addClass('scrlend');
	}	else {
		body.removeClass('scrlend');
	}
}


async function ScrollSmootherKill() {
	await new Promise((resolve, reject) => {
		ScrollTrigger.killAll();
		destroyThree();
		resolve();
	})
}


var tar_offset;
function pageScroll(){
	if(window.matchMedia('(max-width:767px)').matches){
		tar_offset = 60;
	}else{
		tar_offset = 120;
	}
	$('a.anchor').on('click', function(e){
		e.preventDefault();
		nav_hide();
		var hrefPageUrl = $(this).prop("href");
		hrefPageUrl = hrefPageUrl.split("#")[0];
		var currentUrl = location.href;
		currentUrl = currentUrl.split("#")[0];
		if( hrefPageUrl == currentUrl ){
			let href = this.hash;
			gsap.to(window, { duration:0.6, scrollTo:{y:href, autoKill:false, offsetY:tar_offset}, ease:'power2'});	
		}
	});
}


function formInit(){
	const initFormrun = () => {
		window.Formrun._reset();
		window.Formrun.init('.formrun');
	}
	const loadFormrunScript = () => {
		if (window.Formrun) {
			initFormrun();
			return;
		}
		new Promise((resolve, reject) => {
			const script = document.createElement('script')
			script.addEventListener('load', resolve)
			script.addEventListener('error', reject)
			script.src = 'https://sdk.form.run/js/v2/formrun.js'
			document.head.appendChild(script)
		}).then(() => {
			initFormrun()
		})
		.catch(() => {
		})
	}
	loadFormrunScript();
}


const pointerEvent = () => {
	const cursor = document.getElementById('cursor');
	cursor.classList.remove('hover');
	gsap.set(cursor, {xPercent: -50, yPercent: -50});
	let xTo = gsap.quickSetter(cursor, "x", "px"),
			yTo = gsap.quickSetter(cursor, "y", "px");
	let mm = gsap.matchMedia();
	mm.add("(min-width: 768px)", () => {
		window.addEventListener("mousemove", e => {
			xTo(e.clientX);
			yTo(e.clientY);
		});
	});
	mm.add("(max-width: 767px)", () => {
		window.removeEventListener("mousemove", e => {
			xTo(e.clientX);
			yTo(e.clientY);
		});
	});

	document.body.addEventListener("mouseenter", function(e) {
		if((e.target.tagName === "A")||(e.target.tagName === "BUTTON")||(e.target.tagName === "INPUT")) {
			cursor.classList.add('hover');
		}
	},true);
	document.body.addEventListener("mouseleave", function(e) {
		if((e.target.tagName === "A")||(e.target.tagName === "BUTTON")||(e.target.tagName === "INPUT")) {
			cursor.classList.remove('hover');
		}
	},true);
}

const teleRad_swiper = () => {
	const swiper = new Swiper('#feature_swiper', {
		slidesPerView: 1,
		spaceBetween: 10,
		loop: true,
		centeredSlides: true,
		keyboard: {
			enabled: true,
		},
		navigation: {
			nextEl: '.swiper-next',
			prevEl: '.swiper-prev',
		},
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
			renderBullet: function (index, className) {
				return '<a class="' + className + '">' + (index + 1) + "</a>";
			},
		},
		breakpoints: {
			768: {
				slidesPerView: 1.8,
			},
		},
	});
}


function contrastBtn(){
	$('#contrast').on('click', function(){
		body.toggleClass('contrast_high');
	});
}


var PageEvents = {
	loadFunc: function(){
		navInit();
		contrastBtn();
	},
	commonFunc: function(){
		this.pjaxReadyFunc();
		OBSERVER.observeEvents(1);
		winH = $(window).height();
		winW = $(window).width();
		footerH = document.querySelector('footer').offsetHeight;
		pointerEvent();
		pageScroll();
		elFunc();
	},
	pjaxReadyFunc: function(){
	},
	topFunc: function(){
		body.addClass('top');
		logoAnimation();
	},
	teleradFunc: function(){
		teleRad_swiper();
	},
	formFunc: function(){
		formInit();
	}
};

function pjaxSettings(){

	barba.use(barbaPrefetch);

	const replaceBogo = function(data){
		let target = '#wrapper';
		if(data){
			target = data.next.html;
		}
		const $newPageBogo = $(target).find('.bogo-language-switcher').html();
		body.find('#bogo').html($newPageBogo);
		$('#bogo').find('a').addClass('nopj');
	}
	replaceBogo();

	const replaceHead = function(data){
		const head = document.head;
		const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [ 
			"meta[name='keywords']"
			,"meta[name='description']"
			,"meta[property^='og']"
			,"meta[name^='twitter']"
			,"meta[itemprop]"
			,"link[itemprop]"
			,"link[rel='prev']"
			,"link[rel='next']"
			,"link[rel='canonical']"
		].join(',');
		const headTags = head.querySelectorAll(removeHeadTags)
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
	}

	var eventDelete = function eventDelete(e) {
		if (e.currentTarget.href === window.location.href) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
	}
	const links = Array.prototype.slice.call(document.querySelectorAll('a[href]'));
		links.forEach(function (link) {
			link.addEventListener('click', function (e) {
			eventDelete(e)
		}, false)
	})

	var gaPush = function gaPush(pagename) {
		if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
			ga('send', 'pageview', pagename);
		}
		if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
			// gtag('config', 'G-Xxxxxxxx', {'page_path': pagename}); 
		}
	}

	var preventSettings = function preventSettings(el) {
		let href = el.getAttribute('href');
		if(href == null){
			href = el.getAttribute('xlink:href');
		}
		if(el.classList.contains('nopj')){
			return true;
		}
		let site_url = location.protocol + '//' + location.host;
		//if site_urlで始まらない場合、外部リンクとして扱う。ただし/から始まる場合は内部リンクとして扱う
		if (!href.startsWith(site_url) && !href.startsWith("/")) {
			el.setAttribute('target','_blank');
			return true;
		}
		let url = location.protocol + '//' + location.host + location.pathname;
		let extract_hash = href.replace(/#.*$/,"");
		if (href.startsWith(location.protocol + '//' + location.host)) {
			if (href.indexOf('#') > -1 && extract_hash != url ){
				return false;
			}
		}
		if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
			el.setAttribute('target','_blank');
			return true;
		}
	}

	async function scroll(data) {
		await new Promise((resolve, reject) => {
			let scrltarget = 0;
			if(data.trigger === "back") {
				scrltarget = scrollPosY;
			}
			scrollPosY = barba.history.current.scroll.y;
			if(data.trigger === "forward"){
				scrltarget = scrollForwardPosY;
			}

			if(location.hash){
				var anchor = document.querySelector( location.hash );
				if(anchor){
					var target = location.hash;
					gsap.to(window, { duration:0, scrollTo:{y:target, autoKill:false, offsetY:tar_offset}, ease:'power2', onComplete:function(){
						resolve();
					}});	
				}else{
					gsap.to(window, { duration:0.2, scrollTo:0, ease:'power2', onComplete:function(){
						resolve();
					}});
				}
			}else{
				gsap.to(window, { duration:0.2, scrollTo:scrltarget, ease:'power2', onComplete:function(){
					resolve();
				}});
			}
			
		})
	}

	var $footerelm = $('footer');
	barba.init({
		prevent: function prevent(_ref) {
			var el = _ref.el;
			return preventSettings(el);
		},
		timeout: 10000,			
		transitions: [{
			name: 'transition',
			sync:true,
			leave:function(data) {
				const done = this.async();
				gsap.to('footer', {opacity:0, duration:0.2})
				gsap.to(data.current.container, {opacity:0, duration:0.2, onComplete:function(){
					ScrollSmootherKill();
					done();
				}});
			},
			enter:function(data) {
				const done = this.async();
				const $elm = $(data.next.container);
				$elm.css({'opacity':0});
				done();
			}
		}]
	})

	barba.hooks.beforeLeave(() => {
		body.removeClass('ready top inpage scrl topready navopen scrldown scrlend');
	});

	barba.hooks.beforeEnter((data) => {
		replaceHead(data);
		replaceBogo(data);
		if( OBSERVER.handlers.length ){
			OBSERVER.clearEvents();
		}
		gaPush(location.pathname);
	});

	if (history.scrollRestoration) {
		history.scrollRestoration = 'manual';
	}
	var scrollPosY = 0;
	var scrollForwardPosY = 0;
	barba.hooks.leave((data) => {
		scrollForwardPosY = data.current.container.scroll.y;
	});
	barba.hooks.after((data) => {
		async function callerFun(){
			await scroll(data);
			pageCheck();
			gsap.to('footer', {opacity:1, duration:0.2})
			gsap.to(data.next.container, {opacity:1, duration:0.2, onComplete:function(){
			}});
		}
		$('#container').imagesLoaded( function() {
			callerFun();
		});
	});
}

function pageCheck(){
	PageEvents.commonFunc();

	if( document.getElementById('top') ){
		PageEvents.topFunc();
	}else{
		body.addClass('inpage');
	}
	if( document.getElementById('form_blc') ){
		PageEvents.formFunc();
	}
	if( document.getElementById('telerad') ){
		PageEvents.teleradFunc();
	}
	body.addClass('ready');
}

function initialize(){
	window.addEventListener('DOMContentLoaded', function(){
		PageEvents.loadFunc();
		pageCheck();
		pjaxSettings();
	});
}

initialize();

})();
})( jQuery );

}