import gsap from '../lib/gsap.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollSmoother from '../lib/ScrollSmoother.min';
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

const tmpdir = document.body.getAttribute('data-tmpdir');


let myReq;
let smoother;
let canvasResize;
let scene, camera, light, controls;

export function logoAnimation() {

var mm = gsap.matchMedia();

function threeinit(){
	let _w = window.innerWidth;
	let _h = window.innerHeight;
	mm.add("(max-width: 767px)", () => {
		_h = window.innerWidth;
	});


	const modelGroup = new THREE.Group();
	let instArr = [];
	//Renderer
	const renderer = new THREE.WebGLRenderer({
			canvas: document.querySelector('#logocanvas'),
			alpha: true,
			antialias: true, 
	});
	renderer.setPixelRatio(window.devicePixelRatio);
	renderer.setSize(_w, _h);
	renderer.shadowMap.enabled = true;
	renderer.shadowMap.type = THREE.PCFSoftShadowMap;

	scene = new THREE.Scene();
	camera = new THREE.PerspectiveCamera( 35, _w / _h, 1, 100 );
	camera.position.set(0,0.1,3);
	scene.add( camera );
	// controls = new OrbitControls(camera, renderer.domElement);

	light = new THREE.AmbientLight(0xFFFFFF, 1.5);
	scene.add(light);

	const directLight = new THREE.DirectionalLight(0xFFFFFF, 6);
	directLight.position.set(-6,4,4).normalize();
	directLight.castShadow = true;
	directLight.shadow.mapSize.width = 4096;
	directLight.shadow.mapSize.height = 4096;
	scene.add(directLight);

	// var directionalLightShadowHelper = new THREE.CameraHelper( directLight.shadow.camera);
	// scene.add( directionalLightShadowHelper);

	const dracoLoader = new DRACOLoader();
	dracoLoader.setDecoderPath(tmpdir+'/draco/');
	const loader = new GLTFLoader();
	loader.setDRACOLoader(dracoLoader);
	const datasrc = document.getElementById('logocanvas').getAttribute('data-src');

	loader.load(datasrc, (gltf) => {
		const modelScene = gltf.scene;
		const elmNames = ['Top', 'Middle', 'Bottom'];
		for (let i=0; i<elmNames.length; i++) {
			const element = modelScene.getObjectByName(elmNames[i]);
			const inst = element.clone();
			modelGroup.add(inst);
			instArr.push(inst);
		}

		const material = new THREE.MeshLambertMaterial({ color: 0x25877c });
		modelGroup.traverse(function (child) {
			child.castShadow = true;
			child.receiveShadow = true;
			if (child.isMesh) {
				child.material = material;
			}
		});
		modelGroup.scale.set(0.5,0.5,0.5);
		
		scene.add(modelGroup);
		
		threeSmoother();
	});

	const threeSmoother = () => {
		// ===== ScrollSmoother =====
		let instY = [];
		instArr.forEach((inst, i) => {
			instY.push(inst.position.y);
		});

		const green = new THREE.Color(0x25877c);
		const pink = new THREE.Color(0xff8c7d);
		smoother = ScrollTrigger.create({
			trigger: 'body',
			start: "top top",
			scrub: true,
			onUpdate: self => {
				const _velo = self.getVelocity();
				const _y = _velo * 0.001;
				let _instArr,_instY;
				if(_y > 0){
					_instArr = Array.from(instArr).reverse();
					_instY = Array.from(instY).reverse();
				}else{
					_instArr = instArr;
					_instY = instY;
				}
				_instArr.forEach((inst, i) => {
					let _posy = _instY[i] + _y;
					_posy = (_posy < -1) ? -1 : (_posy > 1) ? 1 : _posy;
					gsap.timeline()
					.to(inst.position, {
						y:_posy,
						duration: 0.8,
						delay: i * 0.08,
						overwrite: 'auto',
					})
					.to(inst.position, {
						y:_instY[i],
						duration: 1.2,
						ease: "power2.out",
						overwrite: 'auto',
					}, '-=0.4');

					if (inst.isMesh) {
						gsap.timeline()
						.to(inst.material.color, {
							r: pink.r,
							g: pink.g,
							b: pink.b,
							duration: 0.8,
							ease: "power2.out",
							overwrite: 'auto',
						})
						.to(inst.material.color, {
							r: green.r,
							g: green.g,
							b: green.b,
							duration: 1.2,
							ease: "power2.out",
							overwrite: 'auto',
							onComplete:function(){
							}
						}, '-=0.4');	
					}
				});

				let _rotatex = (Math.PI/36) * _velo * -0.001;
				gsap.timeline()
				.to(modelGroup.rotation, {
					x:_rotatex,
					duration: 0.8,
					overwrite: 'auto',
				})
				.to(modelGroup.rotation, {
					x:0,
					duration: 1.2,
					ease: "power2.out",
					overwrite: 'auto'
				}, '-=0.4');

			},
		});
		// ===== ScrollSmoother =====
	}	

	
	let frame = 0;
	const tick = () => {
		myReq = requestAnimationFrame(tick);
		frame++;
		if (frame % 2 == 0) {
			return;
		}
		renderer.render(scene, camera);
	}	
	tick();


	// contents Height
	function setContentsH(){
		let contentsHeight = document.getElementById('maincontents').offsetHeight;
		document.getElementById('smooth-content').style.height = contentsHeight + 'px';
	}
	// threejs Resize
	function threejsResize() {
		let width = window.innerWidth;
		let height = window.innerHeight;
		mm.add("(max-width: 767px)", () => {
			height = window.innerWidth;
		});	
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.setSize(width, height);
		camera.aspect = width / height;
		camera.updateProjectionMatrix();
	}
	canvasResize = () => {
		setContentsH();
		threejsResize();
	}
	canvasResize();
	window.addEventListener('resize', canvasResize);
}

threeinit();

gsap.from('#logocanvas', {
	opacity: 0,
	duration: 1.2,
	ease: "power4.out",
	delay: 0.8,
});


}


export function destroyThree() {
	cancelAnimationFrame(myReq);
	ScrollTrigger.killAll();
	window.removeEventListener('resize', canvasResize);
	scene = null;
	camera = null;
	light = null;
	controls = null;
}



